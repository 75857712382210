import {combineReducers} from "redux";
import { cartreducer } from "./reducercart";


const rootred = combineReducers({
    cartreducer

});


export default rootred